// Header.js
import React from 'react';
import { Flex, Image, Heading } from '@chakra-ui/react';
import icon from '../../assets/logo.png'; // Adjust the path to your icon

const Header = () => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      borderBottomWidth="1px"
      borderBottomColor="gray.300"
      py="25px"
    >
      <Image src={icon} alt="hoo.be logo" boxSize="40px" mr={3} />
      <Heading size="lg" color="gray.800">
        hoo.be
      </Heading>
    </Flex>
  );
};

export default Header;
