// Offer.js
import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Box, Spinner } from "@chakra-ui/react";
import axios from "axios";
import Header from "./offer/Header";
import Hero from "./offer/Hero";
import Price from "./offer/Price";
import Accepted from "./offer/Accepted";
import Rejected from "./offer/Rejected";
import None from "./offer/None";
import NotFound from "./NotFound";
import Tracking from "./offer/Tracking";
import Completed from "./offer/Completed";
import Countered from "./offer/Countered";
import Footer from "./offer/Footer";
// import Data from "./offer/Data";

function Offer() {
  const { workspaceId, campaignId, creatorId } = useParams();

  const [offerData, setOfferData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchOfferData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOOBE_API_URL}/campaigns-offer-page`,
        {
          workspaceId,
          campaignId,
          creatorId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setOfferData(response.data);
    } catch (err) {
      console.log("Error fetching offer data:", err);
    } finally {
      setIsLoading(false);
    }
  }, [workspaceId, campaignId, creatorId]);

  useEffect(() => {
    fetchOfferData();
  }, [fetchOfferData]);

  return (
    <Box>
      <Header />

      {isLoading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          minH="calc(100vh - 90px)"
        >
          <Spinner size="xl" />
        </Box>
      )}

      {!isLoading && offerData === null && <NotFound />}

      {offerData && (
        <Box minH="calc(100vh - 90px)" p={4}>
          {["None"].includes(offerData.creator.status) && (
            <None offerData={offerData} />
          )}

          {["Contacted", "Countered"].includes(offerData.creator.status) && (
            <Hero offerData={offerData} />
          )}

          {["Contacted"].includes(offerData.creator.status) && (
            <Price offerData={offerData} fetchOfferData={fetchOfferData} />
          )}

          {["Countered"].includes(offerData.creator.status) && (
            <Countered offerData={offerData} fetchOfferData={fetchOfferData} />
          )}

          {["Accepted"].includes(offerData.creator.status) && (
            <Accepted offerData={offerData} fetchOfferData={fetchOfferData} />
          )}

          {["Rejected"].includes(offerData.creator.status) && (
            <Rejected offerData={offerData} fetchOfferData={fetchOfferData} />
          )}

          {["Confirmed"].includes(offerData.creator.status) && (
            <Tracking offerData={offerData} fetchOfferData={fetchOfferData} />
          )}

          {["Completed"].includes(offerData.creator.status) && (
            <Completed offerData={offerData} fetchOfferData={fetchOfferData} />
          )}
          {/* <Data offerData={offerData}/> */}
        </Box>
      )}
      <Footer />
    </Box>
  );
}

export default Offer;
