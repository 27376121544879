import React from "react";
import { Flex, VStack, HStack, Text, Image, Divider } from "@chakra-ui/react";
import verifiedBadge from "../../assets/verifiedbadge.png";
import placeholderImage from "../../assets/placeholder.jpg";

function Hero({ offerData }) {
  if (!offerData || !offerData.creator || !offerData.campaignDetails) return null;

  const { creator, campaignDetails } = offerData;

  return (
    <Flex mt={10} justify="center" align="center">
      <VStack spacing={4} align="center">
        <HStack position="relative">
          <Image
            boxSize="80px"
            src={creator.instagram_profile_pic_url}
            alt={`${creator.name} profile`}
            borderRadius="full"
            mr={4}
          />
          {creator.instagram_is_verified && (
            <Image
              position="absolute"
              top={1}
              left={1}
              boxSize="25px"
              src={verifiedBadge}
              alt="Verified Badge"
            />
          )}
          <VStack align="left">
            <Text fontSize="2xl">Hey 👋</Text>
            <Text fontSize="3xl" fontWeight="bold">
              {creator.name},
            </Text>
          </VStack>
        </HStack>
        <Divider />

        <Text
          fontSize="3xl"
          textAlign="center"
          fontWeight="bold"
          bgGradient="linear(to-r, blue.400, #2ba1d9)"
          bgClip="text"
        >
          You've been invited!
        </Text>
        <Text fontSize="2xl" textAlign="center">
          <strong>{campaignDetails.name}</strong> wants to collab ✉️
        </Text>

        <Image
          boxSize="100px"
          src={campaignDetails.imageURL || placeholderImage}
          alt={`${campaignDetails.name} logo`}
          borderRadius="xl"
        />
        <Text fontSize="2xl" textAlign="center" color="gray.600">
          {campaignDetails.description}
        </Text>
      </VStack>
    </Flex>
  );
}

export default Hero;