import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  GridItem,
  Image,
  Text,
  VStack,
  HStack,
  Button,
  Link,
  Avatar,
  useToast,
  Progress,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { useParams } from 'react-router-dom';

// Import icons and images
import instagramIcon from '../../assets/instagrampost.png';
import instagramReel from '../../assets/instagramreel.png';
import instagramStory from '../../assets/instagramstory.png';
import tiktokPostIcon from '../../assets/tiktok.png';
import placeholderImage from '../../assets/placeholder.jpg';
import verifiedBadge from '../../assets/verifiedbadge.png';

// Import FormatNumber from utils
import { FormatNumber } from '../utils/FormatNumber';

// Helper functions moved outside the component
const getContentType = (type) => {
  if (type.toLowerCase().includes('post')) return 'post';
  if (type.toLowerCase().includes('story')) return 'story';
  if (type.toLowerCase().includes('reel')) return 'reel';
  if (type.toLowerCase().includes('video')) return 'video';
  return '';
};

const calculateDeliverableStatus = (deliverables = [], trackingData = []) => {
  return deliverables.map((deliverable) => {
    const { count: requiredCount, type } = deliverable;
    const platform = type.toLowerCase().includes('tiktok')
      ? 'tiktok'
      : 'instagram';
    const contentType = getContentType(type);

    // Count the number of matching and logged posts in trackingData
    const currentCount = trackingData.filter(
      (item) =>
        item.logged &&
        item.platform.toLowerCase() === platform &&
        item.type.toLowerCase() === contentType
    ).length;

    return {
      type,
      platform,
      contentType,
      requiredCount,
      currentCount,
    };
  });
};

const TrackingDetails = ({ trackingData, offerData }) => {
  const { workspaceId, campaignId, creatorId } = useParams();
  const [localTrackingData, setLocalTrackingData] = useState([]);
  const [deliverableStatus, setDeliverableStatus] = useState([]);
  const [canCompleteCampaign, setCanCompleteCampaign] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (trackingData) {
      setLocalTrackingData(trackingData);
    }
  }, [trackingData]);

  useEffect(() => {
    if (
      offerData &&
      offerData.creator &&
      offerData.creator.deliverables &&
      localTrackingData
    ) {
      const status = calculateDeliverableStatus(
        offerData.creator.deliverables,
        localTrackingData
      );
      setDeliverableStatus(status);

      // Check if all deliverables are met
      const allMet = status.every(
        (item) => item.currentCount >= item.requiredCount
      );
      setCanCompleteCampaign(allMet);
    }
  }, [offerData, localTrackingData]);

  const handleCompleteCampaign = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/campaigns-offer-complete`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ workspaceId, campaignId, creatorId }),
        }
      );

      if (response.ok) {
        // Handle success, e.g., show a toast notification
        toast({
          title: 'Campaign completed successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        window.location.reload();

      } else {
        // Handle server errors
        const errorData = await response.json();
        console.error('Failed to complete the campaign:', errorData);

        toast({
          title: 'Failed to complete the campaign',
          description: errorData.message || 'Server error',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      // Handle network or other errors
      console.error('An error occurred while completing the campaign:', error);

      toast({
        title: 'An error occurred',
        description: 'Unable to complete the campaign.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleLogPost = async (index) => {
    const item = localTrackingData[index];

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/campaigns-offer-tracking-log`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            workspaceId,
            campaignId,
            creatorId,
            postInfo: item,
          }),
        }
      );

      if (response.ok) {
        // Update the logged state to true when the request is successful
        setLocalTrackingData((prevData) => {
          const newData = [...prevData];
          newData[index].logged = true;
          return newData;
        });

        toast({
          title: 'Successfully added post',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        console.error('Failed to log the post.');
      }
    } catch (error) {
      console.error('An error occurred while logging the post:', error);
    }
  };

  const getPostTypeIcon = (post) => {
    const platform = post.platform.toLowerCase();
    const type = post.type.toLowerCase();

    if (platform === 'instagram') {
      if (type.includes('post')) return instagramIcon;
      if (type.includes('reel')) return instagramReel;
      if (type.includes('story')) return instagramStory;
    } else if (platform === 'tiktok') {
      return tiktokPostIcon;
    }
    return null;
  };

  const getDeliverableIcon = (platform, contentType) => {
    if (platform === 'instagram') {
      if (contentType === 'post') return instagramIcon;
      if (contentType === 'reel') return instagramReel;
      if (contentType === 'story') return instagramStory;
    } else if (platform === 'tiktok') {
      return tiktokPostIcon;
    }
    return null;
  };

  return (
    <Box>
      <Box width="100%" mx="auto" p={4} mb={10} maxW="600px">
        {localTrackingData && localTrackingData.length > 0 ? (
          <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={6}>
            {localTrackingData.map((post, index) => (
              <Box
                key={index}
                width="100%"
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
              >
                {/* Post Content */}
                <Link href={post.content_url} isExternal>
                  <Image
                    src={post.thumbnail_url || placeholderImage}
                    alt={post.caption || 'Post Image'}
                    objectFit="cover"
                    width="100%"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = placeholderImage;
                    }}
                  />
                </Link>

                <VStack align="start" p={4} spacing={2}>
                  {/* Source Information */}
                  <HStack spacing={2} mt={2}>
                    <Box position="relative">
                      <Avatar
                        size="sm"
                        src={post.profile_pic_url}
                        name={post.name}
                      />
                      {post.is_verified && (
                        <Image
                          src={verifiedBadge}
                          alt="Verified Badge"
                          boxSize="12px"
                          position="absolute"
                          top="-2px"
                          left="-2px"
                        />
                      )}
                    </Box>
                    <VStack align="start" spacing={0}>
                      <Text fontWeight="bold" fontSize="sm">
                        {post.name}
                      </Text>
                      <Text fontSize="sm" color="gray.500">
                        @{post.username}
                      </Text>
                    </VStack>
                  </HStack>

                  {/* Caption */}
                  {post.caption && (
                    <Text fontSize="sm" noOfLines={3}>
                      {post.caption}
                    </Text>
                  )}

                  {/* View Media Button */}
                  <Button
                    as="a"
                    href={post.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    leftIcon={
                      getPostTypeIcon(post) ? (
                        <Image
                          src={getPostTypeIcon(post)}
                          alt={`${post.type} icon`}
                          boxSize="20px"
                        />
                      ) : (
                        <ExternalLinkIcon />
                      )
                    }
                    size="sm"
                    variant="outline"
                    mt={2}
                  >
                    View Media <ExternalLinkIcon ml={2} />
                  </Button>

                  {/* Statistics as 2x2 Grid with Boxes and Text */}
                  <Grid
                    templateColumns="repeat(2, 1fr)"
                    gap={4}
                    mt={2}
                    width="100%"
                  >
                    <GridItem>
                      <Box
                        borderWidth="1px"
                        borderRadius="md"
                        p={2}
                        textAlign="center"
                      >
                        <Text fontSize="sm" color="gray.500">
                          Impressions
                        </Text>
                        <Text fontSize="lg" fontWeight="bold">
                          {FormatNumber(post.impressions)}
                        </Text>
                      </Box>
                    </GridItem>
                    <GridItem>
                      <Box
                        borderWidth="1px"
                        borderRadius="md"
                        p={2}
                        textAlign="center"
                      >
                        <Text fontSize="sm" color="gray.500">
                          Comments
                        </Text>
                        <Text fontSize="lg" fontWeight="bold">
                          {FormatNumber(post.comments)}
                        </Text>
                      </Box>
                    </GridItem>
                    <GridItem>
                      <Box
                        borderWidth="1px"
                        borderRadius="md"
                        p={2}
                        textAlign="center"
                      >
                        <Text fontSize="sm" color="gray.500">
                          Likes
                        </Text>
                        <Text fontSize="lg" fontWeight="bold">
                          {FormatNumber(post.likes)}
                        </Text>
                      </Box>
                    </GridItem>
                    <GridItem>
                      <Box
                        borderWidth="1px"
                        borderRadius="md"
                        p={2}
                        textAlign="center"
                      >
                        <Text fontSize="sm" color="gray.500">
                          Shares
                        </Text>
                        <Text fontSize="lg" fontWeight="bold">
                          {FormatNumber(post.shares)}
                        </Text>
                      </Box>
                    </GridItem>
                  </Grid>

                  {/* Log Post Button */}
                  {!post.logged && (
                    <Button
                      mt={3}
                      colorScheme="blue"
                      w="full"
                      onClick={() => handleLogPost(index)}
                      bgGradient="linear(to-r, blue.400, #2ba1d9)"
                      _hover={{
                        transform: 'scale(1.02)',
                      }}
                    >
                      Add Media
                    </Button>
                  )}
                </VStack>
              </Box>
            ))}
          </Grid>
        ) : (
          // Display message when there is no tracking data
          <Box textAlign="center" mt={10}>
            <Text fontSize="lg">You currently have no posts!</Text>
          </Box>
        )}
      </Box>

      {/* Deliverable Status */}
      <Box textAlign="center">
        <Text fontSize="2xl" fontWeight="bold" mb={5}>
          Deliverable Progress
        </Text>
        {deliverableStatus.length > 0 ? (
          <VStack spacing={4}>
            {deliverableStatus.map((item, index) => {
              const progress =
                (item.currentCount / item.requiredCount) * 100;
              return (
                <Box
                  key={index}
                  width="60%"
                  p={4}
                  borderWidth="1px"
                  borderRadius="md"
                >
                  <HStack spacing={4}>
                    <Image
                      src={getDeliverableIcon(
                        item.platform,
                        item.contentType
                      )}
                      alt={`${item.type} icon`}
                      boxSize="40px"
                    />
                    <VStack align="start" spacing={1} flex="1">
                      <Text fontWeight="bold" fontSize="lg">
                        {item.type}
                      </Text>
                      <Progress
                        value={progress}
                        size="sm"
                        colorScheme="blue"
                        borderRadius="md"
                        width="100%"
                        bg="gray.300"
                      />
                      <Text fontSize="lg" color="gray.600">
                        {item.currentCount}/{item.requiredCount} completed
                      </Text>
                    </VStack>
                  </HStack>
                </Box>
              );
            })}
          </VStack>
        ) : (
          <Text>No deliverables found.</Text>
        )}
      </Box>

      {/* "Complete Campaign" button */}
      <Box mt={10} mb={100} textAlign="center">
        <Button
          bgGradient="linear(to-r, blue.400, #2ba1d9)"
          colorScheme="blue"
          isDisabled={!canCompleteCampaign}
          onClick={handleCompleteCampaign}
          size="lg"
          w="450px"
        >
          Complete Campaign
        </Button>
      </Box>
    </Box>
  );
};

export default TrackingDetails;
