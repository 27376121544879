// Price.js
import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  VStack,
  Button,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  FormControl,
  FormLabel,
  useToast,
  NumberInput,
  NumberInputField,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import Deliverables from "./Deliverables";
import { useParams } from "react-router-dom";

function Price({ offerData, fetchOfferData }) {
  const { workspaceId, campaignId, creatorId } = useParams();

  const existingOfferAmount =
    offerData?.creator &&
    typeof offerData.creator.offer === "number" &&
    offerData.creator.offer > 0
      ? offerData.creator.offer
      : null;

  const [selectedOffer, setSelectedOffer] = useState(
    existingOfferAmount || 100
  );

  const [inputOfferValue, setInputOfferValue] = useState(
    (existingOfferAmount || 100).toFixed(2)
  );

  const [deliverables, setDeliverables] = useState(
    offerData?.creator?.deliverables &&
    Array.isArray(offerData.creator.deliverables)
      ? offerData.creator.deliverables
      : []
  );

  const [isEditing, setIsEditing] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const campaignName = offerData?.campaignDetails?.name || "Unknown Campaign";

  useEffect(() => {
    if (existingOfferAmount !== null) {
      setSelectedOffer(existingOfferAmount);
      setInputOfferValue(existingOfferAmount.toFixed(2));
    }
  }, [existingOfferAmount]);

  useEffect(() => {
    if (
      existingOfferAmount !== null &&
      (selectedOffer !== existingOfferAmount ||
        JSON.stringify(deliverables) !==
          JSON.stringify(offerData?.creator?.deliverables || []))
    ) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  }, [selectedOffer, deliverables, existingOfferAmount, offerData]);

  if (!offerData) return null;

  const handleButtonClick = async () => {
    if (existingOfferAmount !== null && !hasChanges) {
      try {
        setIsLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_HOOBE_API_URL}/campaigns-offer-accept`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              workspaceId,
              campaignId,
              creatorId,
              offerAmount: existingOfferAmount,
              deliverables: deliverables,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        console.log(data);

        toast({
          title: "Offer Accepted",
          description: `You have accepted the offer of $${existingOfferAmount.toFixed(
            2
          )}.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        fetchOfferData();
      } catch (error) {
        console.error("Error accepting offer:", error);
        toast({
          title: "Error",
          description: "There was an error accepting the offer.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      if (selectedOffer <= 0) {
        toast({
          title: "Invalid Offer",
          description: "Please select an offer amount greater than 0.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (deliverables.length === 0) {
        toast({
          title: "No Deliverables",
          description: "Please add at least one deliverable.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      try {
        setIsLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_HOOBE_API_URL}/campaigns-offer-counter`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              workspaceId,
              campaignId,
              creatorId,
              offerAmount: selectedOffer,
              deliverables: deliverables,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        console.log(data);

        toast({
          title: "Offer Submitted",
          description: `You have submitted an offer of $${selectedOffer.toFixed(
            2
          )}.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        fetchOfferData();

        setIsEditing(false);
        setHasChanges(false);
      } catch (error) {
        console.error("Error submitting offer:", error);
        toast({
          title: "Error",
          description: "There was an error submitting your offer.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const isButtonDisabled = selectedOffer <= 0 || deliverables.length === 0;

  return (
    <Box maxW="400px" mx="auto" mt={5}>
      <VStack spacing={6} textAlign="center">
        <Text fontSize="2xl">
          <strong>{campaignName}</strong> is proposing...
        </Text>

        <Deliverables
          initialDeliverables={deliverables}
          onChange={(newDeliverables) => {
            setDeliverables(newDeliverables);
            setHasChanges(true);
          }}
          isEditable={isEditing || deliverables.length === 0}
        />

        {existingOfferAmount !== null && !isEditing ? (
          <VStack>
            <Text fontSize="lg">Offer Amount</Text>
            <Text fontSize="3xl">
              <strong>{`$${existingOfferAmount.toFixed(2)}`}</strong>
            </Text>
          </VStack>
        ) : (
          <FormControl id="offerAmount">
            <FormLabel>
              What is your rate for the proposed deliverables:
            </FormLabel>
            <VStack spacing={4}>
              <Slider
                aria-label="offer-slider"
                value={Math.min(selectedOffer, 1000)}
                onChange={(val) => {
                  setSelectedOffer(val);
                  setInputOfferValue(val.toFixed(2));
                  setHasChanges(true);
                }}
                min={50}
                max={1000}
                step={1}
                colorScheme="blue"
              >
                <SliderTrack h="10px" borderRadius="full">
                  <SliderFilledTrack bgGradient="linear(to-r, blue.400, #2ba1d9)" />
                </SliderTrack>
                <SliderThumb
                  borderWidth="3px"
                  borderColor="gray.200"
                  boxSize={8}
                >
                  <Box color="blue" />
                </SliderThumb>
              </Slider>
              <InputGroup size="lg" w="150px">
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.500"
                  fontSize="1.2em"
                  children="$"
                />
                <NumberInput
                  value={inputOfferValue}
                  onChange={(valueString) => {
                    setInputOfferValue(valueString);
                  }}
                  onBlur={() => {
                    const value = parseFloat(inputOfferValue);
                    if (!isNaN(value)) {
                      setSelectedOffer(value);
                      setHasChanges(true);
                    } else {
                      setInputOfferValue(selectedOffer.toFixed(2));
                    }
                  }}
                  min={0}
                  step={0.01}
                  precision={2}
                  keepWithinRange={false}
                  clampValueOnBlur={false}
                >
                  <NumberInputField textAlign="center" pl="1.5em" />
                </NumberInput>
              </InputGroup>
            </VStack>
          </FormControl>
        )}

        <Button
          colorScheme="blue"
          onClick={handleButtonClick}
          width="280px"
          height="70px"
          borderRadius="xl"
          fontSize="24px"
          bgGradient="linear(to-r, blue.400, #2ba1d9)"
          isDisabled={isButtonDisabled || isLoading}
          isLoading={isLoading}
          _hover={{
            transform: "scale(1.05)",
          }}
        >
          {existingOfferAmount !== null && !hasChanges
            ? "Accept Offer"
            : "Submit Offer"}
        </Button>

        {!isEditing && (
          <Button
            size="sm"
            borderRadius="xl"
            bg="white"
            borderWidth="1px"
            onClick={() => setIsEditing(true)}
          >
            Edit Offer
          </Button>
        )}

        {isEditing && (
          <Button
            size="sm"
            borderRadius="xl"
            bg="white"
            borderWidth="1px"
            onClick={() => {
              setIsEditing(false);
              setHasChanges(false);
              setDeliverables(offerData?.creator?.deliverables || []);
              setSelectedOffer(existingOfferAmount || 100);
              setInputOfferValue(
                (existingOfferAmount || 100).toFixed(2)
              );
            }}
          >
            Cancel
          </Button>
        )}
      </VStack>
    </Box>
  );
}

export default Price;
