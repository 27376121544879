import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  VStack,
  HStack,
  Image,
  Badge,
} from '@chakra-ui/react';
import { FaInstagram, FaCheckCircle } from 'react-icons/fa';
import ConfettiExplosion from 'react-confetti-explosion';

const Completed = ({ offerData }) => {
  const { creator, campaignDetails } = offerData;

  // State to trigger confetti
  const [isExploding, setIsExploding] = useState(false);

  useEffect(() => {
    // Trigger confetti on component mount
    setIsExploding(true);
  }, []);

  return (
    <Box
      mx="auto"
      mt={10}
      position="relative"
      textAlign="center"
      mb={100}
    >
      {/* Confetti Explosion */}
      {isExploding && <ConfettiExplosion />}

      {/* Header */}
      <VStack spacing={4} align="center">
        <Text fontSize="3xl" fontWeight="bold" color="teal.500">
          Congratulations!
        </Text>
        <Text fontSize="lg" color="gray.600">
          You have successfully completed the campaign.
        </Text>

        {/* Payout and Thank You */}
        <VStack spacing={4} align="center">
          <Text fontSize="xl" fontWeight="bold" color="teal.500">
            You will be paid out in 10-30 days!
          </Text>
          <Text fontSize="md" color="gray.600">
            Thank you for being awesome and contributing to the success of our campaign!
          </Text>
        </VStack>
      </VStack>

      {/* Creator Information */}
      <Box>
        <Text fontSize="2xl" fontWeight="semibold" mb={4}>
          Creator Information
        </Text>
        <VStack spacing={4} align="center">
          <Image
            src={creator.instagram_profile_pic_url}
            alt={`${creator.name} Profile`}
            boxSize="120px"
            borderRadius="full"
            objectFit="cover"
          />
          <VStack align="center" spacing={1}>
            <Text fontSize="xl" fontWeight="bold">
              {creator.name}{' '}
              {creator.instagram_is_verified && (
                <Badge
                  colorScheme="green"
                  ml={2}
                  display="inline-flex"
                  alignItems="center"
                >
                  <FaCheckCircle style={{ marginRight: '4px' }} />
                  Verified
                </Badge>
              )}
            </Text>
            <HStack spacing={1} justify="center">
              <FaInstagram color="#E1306C" />
              <Text>@{creator.instagram_username}</Text>
            </HStack>
            <Text>
              Followers: {creator.instagram_followers_count.toLocaleString()}
            </Text>
          </VStack>
        </VStack>
      </Box>

      {/* Campaign Information */}
      <Box mt={6}>
        {/* Campaign Name */}
        <Text fontSize="3xl" fontWeight="bold" mb={4}>
          {campaignDetails.name}
        </Text>

        {/* Campaign Description */}
        <Text fontSize="lg" color="gray.600" mb={6}>
          {campaignDetails.description}
        </Text>

        {/* Campaign Image */}
        <Box>
          <Image
            src={campaignDetails.imageURL}
            alt="Campaign Image"
            borderRadius="md"
            boxSize="300px"
            objectFit="cover"
            mx="auto"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Completed;
