import React, { useState } from "react";
import {
  Box,
  Text,
  Button,
  VStack,
  HStack,
  Tag,
  TagLabel,
  Image,
  useToast,
  Flex,
} from "@chakra-ui/react";
import { FaCameraRetro } from "react-icons/fa";
import { useParams } from "react-router-dom";
import Deliverables from "./Deliverables"; // Adjust the path as necessary
import TrackingDetails from "./TrackingDetails"; // Import the new component

// Import icons and images
import tiktokPostIcon from "../../assets/tiktok.png";
import instagramIcon from "../../assets/instagrampost.png";

const Tracking = ({ offerData }) => {
  const { workspaceId, campaignId, creatorId } = useParams();
  const { creator, campaignDetails } = offerData;

  // Separate loading states for scan and complete actions
  const [isScanLoading, setIsScanLoading] = useState(false);
  const [trackingData, setTrackingData] = useState(null);
  const toast = useToast();

  const handleScanProfile = async () => {
    setIsScanLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/campaigns-offer-tracking-fetch`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            workspaceId,
            campaignId,
            creatorId,
            offerData,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch tracking data");
      }

      const data = await response.json();
      setTrackingData(data);

      toast({
        title: "Profile Scanned",
        description: "Your profile has been successfully scanned.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error scanning profile:", error);
      toast({
        title: "Error",
        description: "An error occurred while scanning your profile.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsScanLoading(false);
    }
  };

  const getPlatformIcon = (platform) => {
    if (platform === "TikTok") {
      return tiktokPostIcon;
    } else if (platform === "Instagram") {
      return instagramIcon;
    }
    return null;
  };

  // Create a unique list of platforms and usernames
  const platformUsernamesMap = new Map();

  creator.deliverables.forEach((deliverable) => {
    const type = deliverable.type;
    const platform = type.includes("TikTok") ? "TikTok" : "Instagram";
    const username =
      platform === "TikTok"
        ? creator.tiktok_username
        : creator.instagram_username;
    platformUsernamesMap.set(platform, username);
  });

  const platformUsernames = Array.from(platformUsernamesMap.entries()).map(
    ([platform, username]) => ({ platform, username })
  );

  return (
    <VStack spacing={6} align="center" mx="auto" p={6}>
      <Text fontSize="2xl" fontWeight="bold">
        Now it's time to post! 📱
      </Text>

      {/* Campaign Information */}
      <Flex alignItems="start">
        <Box flexShrink={0} mr={4}>
          <Image
            src={campaignDetails.imageURL}
            alt="Campaign Image"
            boxSize="80px"
            objectFit="cover"
            borderRadius="md"
          />
        </Box>
        <Box>
          <Text fontSize="lg" fontWeight="bold" mb={2}>
            {campaignDetails.name}
          </Text>
          <Text>{campaignDetails.description}</Text>
        </Box>
      </Flex>

      {/* Deliverables */}
      <Box align="center">
        <Text fontSize="lg" mb={2}>
          We're looking for these posts...
        </Text>
        <Deliverables
          initialDeliverables={creator.deliverables}
          isEditable={false}
          onChange={() => {}}
        />
      </Box>

      {/* Posting Instructions */}
      <Box align="center">
        <Text fontSize="lg" mb={2}>
          When posting, make sure to use your account:
        </Text>
        <HStack spacing={2} wrap="wrap" justify="center">
          {platformUsernames.map(({ platform, username }) => (
            <Tag
              size="lg"
              borderWidth="1px"
              borderRadius="full"
              key={platform}
              p={2}
            >
              <Image
                src={getPlatformIcon(platform)}
                alt={`${platform} Icon`}
                boxSize="20px"
                mr={2}
              />
              <TagLabel>@{username}</TagLabel>
            </Tag>
          ))}
        </HStack>
        <Text fontSize="lg" mt={4} mb={2}>
          And include these keywords:
        </Text>
        <HStack spacing={2} wrap="wrap" justify="center">
          {campaignDetails.listeningKeywords.map((keyword, index) => (
            <Tag
              size="lg"
              borderWidth="1px"
              key={index}
              borderRadius="full"
            >
              <TagLabel>{keyword}</TagLabel>
            </Tag>
          ))}
        </HStack>
      </Box>

      {/* Scan Profile Button */}
      <Button
        leftIcon={<FaCameraRetro />}
        colorScheme="blue"
        size="lg"
        onClick={handleScanProfile}
        isLoading={isScanLoading}
        loadingText="Scanning..."
      >
        Scan my profile for new posts
      </Button>

      {/* Tracking Data Display using TrackingDetails Component */}
      <TrackingDetails trackingData={trackingData} offerData={offerData} />
    </VStack>
  );
};

export default Tracking;
