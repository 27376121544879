import React from 'react';
import { Text, Button, Link, VStack, Center } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';

function NotFound() {
  return (
    <Center minHeight="80vh" bg="gray.50">
      <VStack spacing={6}>
        <Text fontSize="3xl" fontWeight="bold" color="gray.700">
          Uh oh!
        </Text>
        <Text fontSize="xl" color="gray.700">
          Looks like there is no offer here.
        </Text>
        <Link href="https://creatorpass.hoobe.me" isExternal _hover={{ textDecoration: 'none' }}>
          <Button colorScheme="blue" size="lg" rightIcon={<ExternalLinkIcon />}>
            Click here to apply to one
          </Button>
        </Link>
      </VStack>
    </Center>
  );
}

export default NotFound;
