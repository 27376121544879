import React from "react";
import { Text, VStack, Flex } from "@chakra-ui/react";
import Deliverables from "./Deliverables";

const Countered = ({ offerData }) => {
  const getLatestTimeline = (timeline) => {
    return timeline.reduce((latest, current) => {
      return new Date(current.timestamp) > new Date(latest.timestamp)
        ? current
        : latest;
    });
  };

  const latestTimeline = getLatestTimeline(offerData.creator.timeline);

  return (
    <Flex justifyContent="center" alignItems="center" mt={10}>
      <VStack spacing={4} align="center">
        <Text fontSize="2xl" fontWeight="bold">
          We've Received Your Offer...
        </Text>
        <VStack spacing={2} align="center">
          <Text>Deliverables</Text>
          <Deliverables
            initialDeliverables={latestTimeline.deliverables}
            isEditable={false}
          />
        </VStack>
        <VStack spacing={2} align="center">
          <Text>Offer Amount</Text>
          <Text fontSize="3xl" fontWeight="bold">
            ${latestTimeline.offerAmount}
          </Text>
        </VStack>
        <Text fontStyle="italic" color="gray.500" textAlign="center">
          We'll get back to your counter offer soon. <br /> Thank you for your
          patience!
        </Text>
      </VStack>
    </Flex>
  );
};

export default Countered;
