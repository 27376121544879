import React, { useState } from "react";
import {
  Box,
  Text,
  Image,
  VStack,
  Button,
  Flex,
  Input,
  Tag,
  TagLabel,
  TagCloseButton,
  HStack,
  Checkbox,
  useToast,
  Heading,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Deliverables from "./Deliverables"; // Import the Deliverables component

const Accepted = ({ offerData }) => {
  const { workspaceId, campaignId, creatorId } = useParams();
  const { creator, campaignDetails } = offerData;

  const [emails, setEmails] = useState(creator.emails || []);
  const [emailInput, setEmailInput] = useState("");
  const [receiveMoreOffers, setReceiveMoreOffers] = useState(true); // Checkbox is checked by default
  const [isLoading, setIsLoading] = useState(false);
  const [isESigned, setIsESigned] = useState(false);
  const [emailError, setEmailError] = useState(false); // State to manage email error
  const toast = useToast();

  const handleAddEmail = () => {
    if (emailInput.trim() !== "") {
      if (validateEmail(emailInput.trim())) {
        if (!emails.includes(emailInput.trim())) {
          setEmails([...emails, emailInput.trim()]);
          setEmailInput("");
          setEmailError(false); // Reset email error when a valid email is added
        } else {
          toast({
            title: "Duplicate Email",
            description: "This email is already added.",
            status: "warning",
            duration: 3000,
            isClosable: true,
          });
        }
      } else {
        toast({
          title: "Invalid Email",
          description: "Please enter a valid email address.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleEmailInputKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddEmail();
    }
  };

  const removeEmail = (emailToRemove) => {
    const updatedEmails = emails.filter((email) => email !== emailToRemove);
    setEmails(updatedEmails);
    if (updatedEmails.length === 0) {
      setEmailError(true); // Show error if no emails are left
    }
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleESign = () => {
    setIsESigned(true);
    toast({
      title: "E-Signature Added",
      description: `You have signed the contract as ${creator.name}.`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleSaveAndSend = async () => {
    if (emails.length === 0) {
      setEmailError(true);
      toast({
        title: "Email Required",
        description: "Please add at least one email before saving.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    try {
      const payload = {
        workspaceId,
        campaignId,
        creatorId,
        offerData,
        emails,
        receiveMoreOffers,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/campaigns-offer-contract-accept`,
        payload
      );

      if (response.status === 200 || response.status === 201) {
        toast({
          title: "Success",
          description: "Your data has been saved and sent successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        window.location.reload();
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      console.error("Error saving and sending data:", error);
      toast({
        title: "Error",
        description:
          "There was an issue saving and sending your data. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box mt={10} mx="auto">
      <VStack>
        <Heading as="h2" size="lg">
          Your offer has been accepted! 🎉
        </Heading>
        <Text as="h3" fontSize="20px">
          One last step...
        </Text>

        <Box align="center" justify="space-between">
          <Image
            src={creator.instagram_profile_pic_url}
            alt={creator.name}
            borderRadius="full"
            boxSize="80px"
            objectFit="cover"
            border="1px solid #e2e8f0"
          />
          <Text fontSize="xl" fontWeight="bold" mb={4}>
            {creator.name}
          </Text>
        </Box>

        <Flex mt={4}>
          {/* First item */}
          <Box textAlign="left" mx={8}>
            <Text>Offer Amount</Text>
            <Text fontSize="2xl" fontWeight="bold" mb={4}>
              ${creator.offer}
            </Text>
          </Box>

          {/* Second item */}
          <Box textAlign="left" mx={8}>
            <Text>Deliverables</Text>
            <Deliverables
              initialDeliverables={creator.deliverables}
              isEditable={false}
            />
          </Box>

          {/* Third item */}
          <Box display="flex" alignItems="center" mx={8}>
            <Image
              src={campaignDetails.imageURL}
              alt={campaignDetails.name}
              borderRadius="xl"
              w="60px"
              mr={4}
            />
            <VStack align="start">
              <Text fontWeight="bold">{campaignDetails.name}</Text>
              <Text>{campaignDetails.description}</Text>
            </VStack>
          </Box>
        </Flex>

        <Heading as="h2" size="md" mt={4}>
          Please sign the agreement
        </Heading>

        <Box
          p={4}
          border="1px solid #e2e8f0"
          borderRadius="md"
          w="700px"
          mt={4}
        >
          <Text fontSize="lg" fontWeight="bold" mb={4}>
            Contract Agreement
          </Text>
          <Text mb={2}>
            This agreement is made between <strong>{creator.name}</strong>{" "}
            ("Creator") and <strong>Your Company Name</strong> ("Company")
            regarding the promotion of <strong>{campaignDetails.name}</strong>{" "}
            products and services.
          </Text>
          <Text mb={2}>
            The Creator agrees to produce and publish the agreed deliverables as
            outlined above in exchange for the offer amount of{" "}
            <strong>${creator.offer}</strong>.
          </Text>
          <Text mb={4}>
            By signing below, both parties agree to the terms and conditions of
            this agreement.
          </Text>
          <Flex justifyContent="center">
            <Button
              colorScheme="blue"
              size="md"
              onClick={handleESign}
              isDisabled={isESigned}
            >
              {isESigned ? "Signed" : "E-Sign Agreement"}
            </Button>
          </Flex>
        </Box>

        {isESigned && (
          <Box textAlign="center" mt={2}>
            <Text fontSize="md" color="gray.600">
              Signed by <strong>{creator.name}</strong>
            </Text>
          </Box>
        )}

        <Box
          p={4}
          border="1px solid #e2e8f0"
          borderRadius="md"
          w="700px"
          mt={4}
        >
          <FormControl isInvalid={emailError}>
            <FormLabel fontSize="md" fontWeight="bold" mb={2}>
              What email should we send the copy to: <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <InputGroup>
              <Input
                placeholder="Enter email"
                value={emailInput}
                onChange={(e) => setEmailInput(e.target.value)}
                onKeyDown={handleEmailInputKeyDown}
                borderColor="gray.300"
                focusBorderColor="gray.500"
              />
              <InputRightElement width="6rem">
                <Button
                  h="1.75rem"
                  size="sm"
                  onClick={handleAddEmail}
                  colorScheme="blue"
                  mr="1"
                >
                  Add Email
                </Button>
              </InputRightElement>
            </InputGroup>
            {emailError && (
              <FormErrorMessage>
                At least one email is required.
              </FormErrorMessage>
            )}
          </FormControl>
          <HStack spacing={2} mt={2} wrap="wrap">
            {emails.map((email, index) => (
              <Tag
                size="md"
                key={index}
                borderRadius="full"
                variant="solid"
                colorScheme="gray"
              >
                <TagLabel>{email}</TagLabel>
                <TagCloseButton onClick={() => removeEmail(email)} />
              </Tag>
            ))}
          </HStack>

          <Checkbox
            mt={4}
            isChecked={receiveMoreOffers}
            onChange={(e) => setReceiveMoreOffers(e.target.checked)}
          >
            I would like to receive more messages about offers from these emails
          </Checkbox>
        </Box>

        <Flex justifyContent="center" mt={4}>
          <Button
            colorScheme="blue"
            size="md"
            onClick={handleSaveAndSend}
            isLoading={isLoading}
            loadingText="Saving..."
            isDisabled={!isESigned}
          >
            Save and Send
          </Button>
        </Flex>
      </VStack>
    </Box>
  );
};

export default Accepted;
